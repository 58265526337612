import { useCallback } from "react"
import { useTrackApi } from "../../api/track"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { useUiService } from "../ui"
import { ITrackPageActionsKey, setCurrentTrackState, setTrackPageActionState, setTracksState } from "../../states/track"

export const useTrackService = () => {
  const dispatch = useAppDispatch()
  const { createTrackApi, deleteTrackApi, getTracksApi, findTrackByIdApi } = useTrackApi()

  const { setAlert } = useUiService()
  const currentUser = useAppSelector((state) => state.user.currentUser)

  const getTracks = useCallback(async () => {
    try {
      if (currentUser.id) {
        const response = await getTracksApi({
          userId: currentUser.id
        })
        dispatch(setTracksState({
          tracks: response
        }))
      }
    } catch (error) {

    }

  }, [
    currentUser,
    dispatch,
    getTracksApi
  ])

  const createTrack = useCallback(async (data: {
    prompt: string

  }) => {

    return createTrackApi({
      userId: currentUser.id,
      prompt: data.prompt
    }).then((response) => {

      dispatch(setCurrentTrackState({
        track: response
      }))
      setAlert({
        message: 'Track created successfully',
        type: 'success'
      })
      // refresh list
      getTracks()
    }).catch((error) => {
      setAlert({
        message: error.message,
        type: 'error'
      })
    })
  }, [createTrackApi, currentUser.id, dispatch, getTracks, setAlert])

  const deleteTrack = useCallback(async (data: {
    trackId: string
  }) => {
    try {
      await deleteTrackApi({
        trackId: data.trackId
      })
      setAlert({
        message: 'Track deleted successfully',
        type: 'success'
      })
      await getTracks()
    } catch (error: any) {
      setAlert({
        message: error.message,
        type: 'error'
      })
    }
  }, [deleteTrackApi, getTracks, setAlert])

  const setCurrentTrack = useCallback(async (data: {
    trackId: string
  }) => {
    try {
      const track = await findTrackByIdApi({
        trackId: data.trackId
      })

      if (track) {
        dispatch(setCurrentTrackState({
          track
        }))
      }
    } catch (error) {
    }
  }, [dispatch, findTrackByIdApi])

  const setTrackPageAction = useCallback(async (data: {
    action: ITrackPageActionsKey
  }) => {
    dispatch(setTrackPageActionState({
      action: data.action
    }))
  }, [dispatch])

  return {
    getTracks,
    createTrack,
    deleteTrack,
    setCurrentTrack,
    setTrackPageAction
  }
}