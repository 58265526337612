import { useCallback } from "react"
import { useQuestionSetApi } from "../../api"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { setCurrentTrackStatsState, setLastTopicsState, setStatsLoadingState } from "../../states/track"
import { useNavigate } from "react-router-dom"
import { resetQuestionSetState, setActiveQuestionIndexState, setAnsweredState, setCreateQuestionSetDialogOpenState, setCurrentQuestionSetState, setFinishedState, setLastTopicsInfoState, setQuestionsState, setSelectedTopicState } from "../../states/question-set"
import { useUiService } from "../ui"
import { setLastQuestionSetsState } from "../../states/subject"

export const useQuestionSetService = () => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const {
    getUserStatsApi,
    createQuestionSetApi,
    findQuestionSetByIdApi,
    findQuestionsByIdsApi,
    updateQuestionSetApi,
    getLastTopicsInfoApi,
    getQuestionSetListApi
  } = useQuestionSetApi()

  const currentUser = useAppSelector((state) => state.user.currentUser)
  const currentTrack = useAppSelector((state) => state.track.currentTrack.track)
  const currentQuestionSet = useAppSelector((state) => state.questionSet.currentQuestionSet)
  const activeQuestionIndex = useAppSelector((state) => state.questionSet.activeQuestionIndex)

  const { setAlert } = useUiService()

  const getUserStats = useCallback(async (data: {
    trackId?: string,
    subjectId?: string
  }) => {
    dispatch(setStatsLoadingState({
      loading: true
    }))
    if (currentUser.id) {
      const response = await getUserStatsApi({
        userId: currentUser.id,
        trackId: data.trackId,
        subjectId: data.subjectId
      })
      dispatch(setCurrentTrackStatsState({
        stats: {
          subjectStats: response.subjectStats
        }
      }))
      dispatch(setStatsLoadingState({
        loading: false
      }))
      dispatch(setLastTopicsState({
        lastTopics: response.lastTopics
      }))

    }
  }, [currentUser.id, dispatch, getUserStatsApi])


  const getLastTopicsInfo = useCallback(async () => {
    if (currentUser.id) {
      const response = await getLastTopicsInfoApi({
        userId: currentUser.id,
        trackId: currentTrack?.id ?? undefined
      })
      dispatch(setLastTopicsInfoState({
        list: response
      }))
    }
  }, [currentTrack?.id, currentUser.id, dispatch, getLastTopicsInfoApi])

  const createQuestionSet = useCallback(async (data: {
    prompt: string,
    topic: string,
    auto: boolean
  }) => {
    try {
      const response = await createQuestionSetApi({
        userId: currentUser.id,
        trackId: currentTrack?.id ?? '',
        prompt: data.prompt,
        topic: data.topic,
        auto: data.auto
      })
      navigate(`/workspace/question-set/${response.id}`)
      return
    } catch (error: any) {
      setAlert({
        message: error?.message ?? 'An error occurred',
        type: 'error'
      })
      return error
    }

  }, [createQuestionSetApi, currentTrack?.id, currentUser.id, navigate, setAlert])


  const setCurrentQuestionSet = useCallback(async (data: {
    id: string
  }) => {
    findQuestionSetByIdApi({
      id: data.id
    }).then((response) => {
      dispatch(setCurrentQuestionSetState({
        questionSet: response
      }))
      findQuestionsByIdsApi({
        ids: response.questions
      }).then((response) => {
        console.log(response)
        dispatch(setQuestionsState({
          questions: response
        }))
      })
    })
  }, [dispatch, findQuestionSetByIdApi, findQuestionsByIdsApi])

  const addAnswer = useCallback(async (data: {
    answer: string,
    type: 'success' | 'error',
  }) => {

    if (!currentQuestionSet) return
    updateQuestionSetApi({
      questionSetId: currentQuestionSet.id,
      answers: [
        ...currentQuestionSet.answers,
        data.answer
      ],
      ended: currentQuestionSet.answers.length + 1 === 10
    }).then((response) => {
      dispatch(setCurrentQuestionSetState({
        questionSet: response
      }))
      dispatch(setAnsweredState({
        answered: true
      }))
      const message = data.type === 'success' ? 'Correct answer' : 'Incorrect answer'
      setAlert({
        message: message,
        type: data.type
      })
    })
  }, [currentQuestionSet, dispatch, setAlert, updateQuestionSetApi])

  const resetAnswered = useCallback(() => {
    dispatch(setAnsweredState({
      answered: false
    }))
  }, [dispatch])

  const nextQuestion = useCallback(() => {
    if (!currentQuestionSet) return
    const nextIndex = activeQuestionIndex + 1
    if (nextIndex < currentQuestionSet.questions.length) {
      dispatch(setActiveQuestionIndexState({
        index: nextIndex
      }))
    } else {
      dispatch(setFinishedState({
        finished: true
      }))
    }
  }, [activeQuestionIndex, currentQuestionSet, dispatch])

  const setCreateQuestionSetDialogOpen = useCallback((data: {
    open: boolean
  }) => {
    dispatch(setCreateQuestionSetDialogOpenState({
      open: data.open
    }))
  }, [dispatch])

  const selectTopicToStudy = useCallback((data: {
    topicId: string,
    topicName: string
  }) => {
    dispatch(setCreateQuestionSetDialogOpenState({
      open: true
    }))
    dispatch(setSelectedTopicState({
      topicId: data.topicId,
      topicName: data.topicName
    }))
  }, [dispatch])


  const resetQuestionSet = useCallback(() => {
    dispatch(resetQuestionSetState())
  }, [
    dispatch
  ])

  const getQuestionSetList = useCallback(async (data: {
    subjectId: string
  }) => {
    try {
      const response = await getQuestionSetListApi({
        subjectId: data.subjectId
      })
      console.log(response)
      dispatch(setLastQuestionSetsState({
        questionSets: response
      }))
    } catch (err) {

    }
  }, [dispatch, getQuestionSetListApi])



  return {
    getUserStats,
    createQuestionSet,
    setCurrentQuestionSet,
    addAnswer,
    resetAnswered,
    nextQuestion,
    getLastTopicsInfo,
    setCreateQuestionSetDialogOpen,
    selectTopicToStudy,
    resetQuestionSet,
    getQuestionSetList
  }
}
