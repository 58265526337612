import { Box, Button, Typography } from "@mui/material"

export const Logo = () => {
  return <>
    <Box display={'flex'} alignItems={"center"}>
      <Button variant='text' color="inherit">
        <img src="/logo.png" alt="logo" style={{
          height: 64
        }} />
        <Typography fontWeight={"bold"} textTransform={'none'}>
          QuironSTD
        </Typography>
      </Button>
    </Box>
  </>
}