import { useMediaQuery, useTheme } from "@mui/material"
import { NavMenuMdLg } from "./NavMenuMdLg"

export const NavMenu = () => {
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.down('lg'))
  return <>
    {
      isSm ? <>
      </>: <>
        <NavMenuMdLg />
      </>
    }
  </>
}