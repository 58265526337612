import { Box, Grid, IconButton, Paper, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useQuestionSetService } from "../../services/question-set/useQuestionSetService"
import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAppSelector } from "../../hooks"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import EditIcon from '@mui/icons-material/Edit';
import { useTrackService } from "../../services/track/useTrackService"
import { CircularProgressWithLabel } from "../../components/CircularProgressWithLabel"
import { LastQuestionSetsSection } from "../../components/LastQuestionSetsSection"





export const SubjectPage = () => {
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.down('lg'))

  const { getUserStats, selectTopicToStudy, getQuestionSetList } = useQuestionSetService()
  const { setCurrentTrack } = useTrackService()
  const stats = useAppSelector((state) => state.track.currentTrack.stats)
  const { subjectId, id } = useParams()
  useEffect(() => {
    if (subjectId) {
      getUserStats({
        subjectId: subjectId
      })
      getQuestionSetList({
        subjectId: subjectId
      })
    }
    if (id) {
      setCurrentTrack({
        trackId: id
      })
    }
  }, [getQuestionSetList, getUserStats, id, setCurrentTrack, subjectId])
  const navigate = useNavigate()
  return <>
    <Grid container style={{
      height: '100%',
      overflowY: isSm ? 'auto' : undefined,
    }} spacing={1}>
      <Grid item xs={12} style={{
        position: 'sticky',
        top: 0,
        zIndex: 100,
      }}>
        <Paper elevation={30} sx={{
          padding: '20px',
        }}>
          <Grid container spacing={3} flexWrap={"nowrap"}>
            <Grid item>
              <IconButton aria-label="delete" onClick={() => {
                navigate(`/workspace/track/${id}`)
              }}>
                <KeyboardBackspaceIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h4">
                {
                  stats?.subjectStats[0]?.subject
                }
              </Typography>
            </Grid>
          </Grid>
        </Paper>

      </Grid>
      <Grid item xs={12} lg={6} style={{
        overflowY: isSm ? undefined : 'auto',
        maxHeight: isSm ? undefined : '100%',
        paddingBottom: '50px'
      }}>
        <Box pl={2} mt={2}>
          <Grid container spacing={3}>
            {
              stats?.subjectStats[0]?.topics.map((item, index) => {
                return <Grid item xs={12} key={index}>
                  <Paper elevation={3} sx={{
                    borderRadius: theme.shape.borderRadius
                  }}>
                    <Box p={3}>
                      <Grid container spacing={3} alignItems={"center"} justifyContent={'space-between'} flexWrap={"nowrap"}>
                        <Grid item xs={isSm ? 12 : undefined}>
                          <Typography color="primary" fontWeight={"bold"} variant="subtitle1">
                            {item.topic}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Grid container alignItems={"center"} spacing={2} flexWrap={'nowrap'}>
                            <Grid item>
                              {/* <Typography style={{
                                color: item.total === 0 ? 'white' : ((item.correct / item.total) < 0.5 ? '#ff1919' : '#26ff5c')
                              }} fontWeight={"bold"} variant="subtitle1">
                                {item.total === 0 ? '0' : ((item.correct / item.total) * 100).toFixed(2)}%
                              </Typography> */}
                              <CircularProgressWithLabel color={
                                item.total === 0 ? undefined : ((item.correct / item.total) < 0.5 ? 'error' : 'success')
                              } value={
                                item.total === 0 ? 0 : ((item.correct / item.total) * 100)
                              } />
                            </Grid>
                            <Grid item>
                              <IconButton color="primary" aria-label="delete" onClick={() => {
                                // navigate(`/workspace/subject/${data.subjectId}`)
                                selectTopicToStudy({
                                  topicId: item.topicId,
                                  topicName: item.topic,
                                })
                              }}>
                                <EditIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>

                      </Grid>

                    </Box>
                  </Paper>
                </Grid>
              })
            }
          </Grid>

        </Box>
      </Grid>
      <Grid item xs={12} lg={6} style={{
        overflowY: isSm ? undefined : 'auto',
        maxHeight: isSm ? undefined : '100%',
        paddingBottom: '50px'
      }}>
        <LastQuestionSetsSection />
      </Grid>
    </Grid>
  </>
}