import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, TextField, useMediaQuery, useTheme } from "@mui/material"
import { useEffect, useState } from "react"
import { TrackCard } from "../../components/TrackCard"
import AddIcon from '@mui/icons-material/Add';
import { toast, Bounce } from "react-toastify";
import { useTrackService } from "../../services/track/useTrackService";
import { useAppSelector } from "../../hooks";
import { useQuestionSetService } from "../../services/question-set/useQuestionSetService";
import { LastTopicsSection } from "../../components/LastTopicsSection";

export const TracksPage = () => {
  const currentUser = useAppSelector((state) => state.user.currentUser)
  const [promptValue, setPromptValue] = useState('')
  const tracks = useAppSelector((state) => state.track.tracks)
  const [loading, setLoading] = useState(false)
  const { getTracks, createTrack } = useTrackService()
  const { getUserStats } = useQuestionSetService()
  useEffect(() => {
    getTracks()
  }, [getTracks])

  useEffect(() => {
    if (currentUser.id) {
      getUserStats({

      })
    }
  }, [currentUser.id, getUserStats])

  const [open, setOpen] = useState(false)
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.down('lg'))



  return <>
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          setLoading(true)
          createTrack({
            prompt: promptValue
          }).then(() => {
            setPromptValue('')
            handleClose();
            setLoading(false)
            getTracks()
          }).catch(() => {
            setPromptValue('')
            toast("Error creating track", {
              position: "top-center",
              type: "error",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              transition: Bounce,
            });
          })
        },
      }}
    >
      <DialogTitle>Create new track</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Input your learning interest or objective. This prompt will guide the generation of your knowledge pathways
        </DialogContentText>
        <TextField
          style={{
            marginTop: '20px'
          }}
          id="outlined-multiline-static"
          label="Type your prompt here"
          multiline
          rows={4}
          defaultValue=""
          fullWidth
          value={promptValue}
          onChange={(e) => setPromptValue(e.target.value)}
        />

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {
          loading ? (
            <Box p={3}>
              <CircularProgress />
            </Box>
          ) : <Button type="submit">create</Button>

        }
      </DialogActions>
    </Dialog>
    <Grid container spacing={2} style={{
      height: '100%',
      overflowY: isSm ? 'auto' : undefined,
    }}>
      <Grid item xs={12} lg={6} style={{
        height: isSm ? undefined : '100%',
        overflowY: isSm ? undefined : 'auto',
        paddingBottom: '100px'
      }}>
        <Grid container spacing={1}>
          {tracks.map((item, index) => {
            return <Grid item xs={12} lg={6} key={index}>
              <TrackCard track={item} getTracks={getTracks} />
            </Grid>
          })}
          {
            currentUser.id && (
              <Grid item xs={12} md={6}>
                <Paper elevation={5} sx={{
                  height: 180,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                  <Box p={3}>
                    <Button onClick={handleClickOpen} color="secondary" endIcon={<AddIcon />}>
                      New Track
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            )
          }
        </Grid>
      </Grid>
      <Grid item xs={12} lg={6}>
        <LastTopicsSection />
      </Grid>
    </Grid>

  </>
}