import { useCallback } from "react"
import { ITrack } from "../../models/ITrack"
import { useAxiosInstance } from "../axios-instance"



export const useTrackApi = () => {
  const { axiosPrivateInstance } = useAxiosInstance()
  const getTracksApi = useCallback(async (data: {
    userId: string
  }): Promise<ITrack[]> => {
    return axiosPrivateInstance.get(`/track`, {
      params: {
        limit: 10,
        filter: {
          userId: data.userId
        }
      }
    }).then((response) => {
      return response.data.tracks
    },)
  }, [
    axiosPrivateInstance
  ])

  const createTrackApi = useCallback((data: {
    userId: string,
    prompt: string
  }): Promise<ITrack> => {
    
    return axiosPrivateInstance.post('/track', {
      data: {
        userId: data.userId,
        prompt: data.prompt
      }
    }).then((response) => {
      return response.data
    })
  }, [
    axiosPrivateInstance
  ])

  const deleteTrackApi = useCallback((data: {
    trackId: string
  }):Promise<void> => {
    return axiosPrivateInstance.delete(`track/${data.trackId}`).then((response) => {
      return response.data
    })
  }, [
    axiosPrivateInstance
  ])

  const findTrackByIdApi = useCallback((data: {
    trackId: string
  }): Promise<ITrack> => {
    return axiosPrivateInstance.get(`/track/${data.trackId}`).then((response) => {
      return response.data.track
    })
  } , [
    axiosPrivateInstance
  ])

  return {
    getTracksApi,
    createTrackApi,
    deleteTrackApi,
    findTrackByIdApi
  }
}