import { CircularProgress, Grid, IconButton, Paper, Typography, useMediaQuery, useTheme } from "@mui/material"
import { SubjectStats } from "../../components"
import { useEffect, useState } from "react"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import { useQuestionSetService } from "../../services/question-set/useQuestionSetService";
import { useTrackService } from "../../services/track/useTrackService";
import { useAppSelector } from "../../hooks";
import { TrackChartsSection } from "./TrackChartsSection";
import { LastTopicsSection } from "../../components/LastTopicsSection";
export const TrackPage = () => {
  let { id } = useParams();

  const stats = useAppSelector((state) => state.track.currentTrack.stats)
  const statsLoading = useAppSelector((state) => state.track.ui.statsLoading)
  const trackPageAction = useAppSelector((state) => state.track.ui.trackPageAction)
  const selectedTrack = useAppSelector((state) => state.track.currentTrack.track)
  const { getUserStats } = useQuestionSetService()
  const { setCurrentTrack, getTracks } = useTrackService()

  useEffect(() => {
    getTracks()
  }, [getTracks])

  useEffect(() => {
    if (id) {
      setCurrentTrack({
        trackId: id
      })
    }
  }, [id, setCurrentTrack])

  useEffect(() => {
    if (id) {
      getUserStats({
        trackId: id
      })
    }
  }, [getUserStats, id])

  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.down('lg'))
  const navigate = useNavigate()
  return <>

    <Grid container style={{
      height: '100%',
      overflowY: isSm ? 'auto' : undefined,
    }} spacing={1}>
      <Grid item xs={12} style={{
        position: 'sticky',
        top: 0,
        zIndex: 100,
      }}>
        <Paper elevation={30} sx={{
          padding: '20px',
        }}>
          <Grid container spacing={3} flexWrap={"nowrap"}>
            <Grid item>
              <IconButton aria-label="delete" onClick={() => {
                navigate(`/workspace/tracks`)
              }}>
                <KeyboardBackspaceIcon />
              </IconButton>

            </Grid>
            <Grid item>
              <Typography variant="h4">{
                statsLoading ? 'Loading...' : selectedTrack?.name
              }</Typography>
            </Grid>
          </Grid>
        </Paper>

      </Grid>
      <Grid item xs={12} lg={6} style={{
        overflowY: isSm ? undefined : 'auto',
        maxHeight: isSm ? undefined : '100%',
        paddingBottom: '50px'
      }}>
        <Grid container spacing={3} style={{
          padding: '20px',
        }}>
          {
            trackPageAction === 'charts' && <Grid item xs={12}>
              <TrackChartsSection />
            </Grid>
          }
          {
            trackPageAction === 'list' && <>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  {
                    statsLoading ? (
                      <Grid item xs={12}>
                        <CircularProgress />
                      </Grid>
                    ) : <>
                      {
                        (stats) && stats.subjectStats.map((item, index) => {
                          let subjectCorrect = 0
                          let subjectTotal = 0
                          for (let i = 0; i < item.topics.length; i++) {
                            subjectCorrect = subjectCorrect + item.topics[i].correct
                            subjectTotal = subjectTotal + item.topics[i].total
                          }
                          return <Grid item xs={12} key={index}>
                            <SubjectStats
                              subjectId={item.subjectId}
                              subjectCorrect={subjectCorrect}
                              subjectTotal={subjectTotal}
                              subject={item.subject}
                              trackId={id}
                            />
                          </Grid>
                        })
                      }
                    </>
                  }
                </Grid>
              </Grid>
            </>
          }
        </Grid>
      </Grid>
      <Grid item xs={12} lg={6}>
        <LastTopicsSection />
      </Grid>
    </Grid >

  </>
}