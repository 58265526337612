import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IFormError, IFormProps } from '../common'
import { IUser } from './types';
import { validateEmail } from '../../utils';


interface ISignUpFormFields {
  name: string,
  email: string,
  confirmEmail: string
  password: string,
  confirmPassword: string
  emailPromotion: boolean
}

interface IUpdateUserFormFields {
  name: string,
}


const signUpFormInitialState: ISignUpFormFields = {
  name: '',
  email: '',
  confirmEmail: '',
  password: '',
  confirmPassword: '',
  emailPromotion: false
}

const updateUserFormInitialState: IUpdateUserFormFields = {
  name: ''
}

interface IUserState {
  signUpForm: IFormProps<ISignUpFormFields>
  updateUserForm: IFormProps<IUpdateUserFormFields>
  currentUser: IUser
}

const initialState: IUserState = {
  signUpForm: {
    fields: signUpFormInitialState,
    touched: false,
    errors: [],
    delivered: false
  },
  updateUserForm: {
    fields: updateUserFormInitialState,
    touched: false,
    errors: [],
    delivered: false
  },
  currentUser: {
    id: '',
    email: '',
    name: ''
  }
};


export const validateSignUpForm = (fields: ISignUpFormFields): IFormError<keyof ISignUpFormFields>[] => {
  const errors: IFormError<keyof ISignUpFormFields>[] = [];

  /* Validate Email */
  if (fields.email === '' || fields.email === undefined) {
    errors.push({
      key: 'email',
      message: 'E-mail is a required field'
    })
  } else {
    if (!validateEmail(fields.email)) {
      errors.push({
        key: 'email',
        message: 'Invalid e-mail format'
      });
    }
  }

  if (fields.confirmEmail !== fields.email) {
    errors.push({
      key: 'confirmEmail',
      message: 'E-mail do not match'
    })
  }

  /* Validate Name*/
  if (fields.name === '' || fields.name === undefined) {
    errors.push({
      key: 'name',
      message: 'Name is a required field'
    })
  }

  /* Validate Password and ConfirmPassword */
  if (fields.password === '' || fields.password === undefined) {
    errors.push({
      key: 'password',
      message: 'Password is a required field'
    })
  } else {
    const regexSpecialCharacter = /[!@#$%&*()_+=|<>?{}[\]~-]/
    const regexLetter = /[a-z]/
    const regexCapitalLetter = /[A-Z]/
    const regexNumber = /[0-9]/

    if (
      fields.password.length < 12 ||
      !regexSpecialCharacter.test(fields.password) ||
      !regexLetter.test(fields.password) ||
      !regexCapitalLetter.test(fields.password) ||
      !regexNumber.test(fields.password)
    ) {
      errors.push({
        key: 'password',
        message: `<ul style="font-weight: bold">
        <li style="color:${fields.password.length >= 12 ? '#59ff85' : 'red'}">
          Password must be at least 12 characters long
        </li>
        <li style="color:${regexLetter.test(fields.password) ? '#59ff85' : 'red'}">
          Include at least one lowercase letter
        </li>
        <li style="color:${regexCapitalLetter.test(fields.password) ? '#59ff85' : 'red'}">
          Include at least one uppercase letter
        </li>
        <li style="color:${regexNumber.test(fields.password) ? '#59ff85' : 'red'}">
          Include at least one number
        </li>
        <li style="color:${regexSpecialCharacter.test(fields.password) ? '#59ff85' : 'red'}">
          Include at least one special character
        </li>
        </ul>`
      })
    }
    if (fields.confirmPassword !== fields.password) {
      errors.push({
        key: 'confirmPassword',
        message: 'Passwords do not match'
      })
    }
  }

  return errors
}

export const validateUpdateUserForm = (fields: IUpdateUserFormFields): IFormError<keyof IUpdateUserFormFields>[] => {
  const errors: IFormError<keyof IUpdateUserFormFields>[] = [];

  if (fields.name === '' || fields.name === undefined) {
    errors.push({
      key: 'name',
      message: 'Name is a required field'
    })
  }

  return errors
}


const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUserState(state, action: PayloadAction<{
      user: IUser
    }>) {
      state.currentUser = action.payload.user;
    },
    setSignUpFormNameState(state, action: PayloadAction<{
      name: string
    }>) {
      state.signUpForm.fields.name = action.payload.name;
    },
    setSignUpFormEmailState(state, action: PayloadAction<{
      email: string
    }>) {
      state.signUpForm.fields.email = action.payload.email;
    },
    setSignUpFormConfirmEmailState(state, action: PayloadAction<{
      confirmEmail: string
    }>) {
      state.signUpForm.fields.confirmEmail = action.payload.confirmEmail;
    },
    setSignUpFormPasswordState(state, action: PayloadAction<{
      password: string
    }>) {
      state.signUpForm.fields.password = action.payload.password;
    },
    setSignUpFormConfirmPasswordState(state, action: PayloadAction<{
      confirmPassword: string
    }>) {
      state.signUpForm.fields.confirmPassword = action.payload.confirmPassword;
    },
    setSignUpFormEmailPromotionState(state, action: PayloadAction<{
      emailPromotion: boolean
    }>) {
      state.signUpForm.fields.emailPromotion = action.payload.emailPromotion;
    },
    setSignUpFormErrorsState(state, action: PayloadAction<{
      errors: IFormError<keyof ISignUpFormFields>[]
    }>) {
      state.signUpForm.errors = action.payload.errors;
    },
    setSignUpFormTouchedState(state, action: PayloadAction<{
      touched: boolean
    }>) {
      state.signUpForm.touched = action.payload.touched;
    },
    setSignUpFormDeliveredState(state, action: PayloadAction<{
      delivered: boolean
    }>) {
      state.signUpForm.delivered = action.payload.delivered;
    },
    resetUserState(state) {
      state = initialState
    },
    setUpdateUserFormNameState(state, action: PayloadAction<{
      name: string
    }>) {
      state.updateUserForm.fields.name = action.payload.name;
    },
    setUpdateUserFormErrorsState(state, action: PayloadAction<{
      errors: IFormError<keyof IUpdateUserFormFields>[]
    }>) {
      state.updateUserForm.errors = action.payload.errors;
    },
    setUpdateUserFormTouchedState(state, action: PayloadAction<{
      touched: boolean
    }>) {
      state.updateUserForm.touched = action.payload.touched;
    } 
  }
});

export const {
  resetUserState,
  setSignUpFormEmailPromotionState,
  setSignUpFormEmailState,
  setSignUpFormNameState,
  setSignUpFormPasswordState,
  setSignUpFormConfirmPasswordState,
  setSignUpFormErrorsState,
  setSignUpFormTouchedState,
  setCurrentUserState,
  setSignUpFormDeliveredState,
  setSignUpFormConfirmEmailState,
  setUpdateUserFormNameState,
  setUpdateUserFormErrorsState,
  setUpdateUserFormTouchedState
} = userSlice.actions;
export const userReducer = userSlice.reducer;