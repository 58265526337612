import React from 'react';
import './App.css';
import { MainRouter } from './MainRouter';
import { ThemeProvider, createTheme, darkScrollbar } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { BackgroundEffect } from './BackgroundEffect';
import { AlertContainer } from './components/alert-container';



const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: darkScrollbar()
      },
    },
  },
});

function App() {
  return (
    <div>
      <ThemeProvider theme={darkTheme}>
        <BackgroundEffect />
        <CssBaseline
        // style scrollbar

        />
        <MainRouter />
        <AlertContainer />
        {/* Same as */}
      </ThemeProvider>
    </div>
  );
}

export default App;
