import { useCallback } from "react"
import { IAlertType, setAlertChangesActionState, setAlertChangesOpenState } from "../../states/ui"
import { Bounce, toast } from "react-toastify"
import { useAppDispatch } from "../../hooks"

export const useUiService = () => {
  const dispatch = useAppDispatch()

  const setAlert = useCallback((data: {
    type: IAlertType,
    message: string
  }) => {
    toast[data.type](data.message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });
  }, [])

  const setAlertChangesSettings = useCallback((data: {
    open: boolean,
    action: () => void
  }) => {
    dispatch(setAlertChangesOpenState({
      open: data.open
    }))
    dispatch(setAlertChangesActionState({
      action: data.action
    }))
  }, [
    dispatch
  ])
  return {
    setAlert,
    setAlertChangesSettings
  }
}