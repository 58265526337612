import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts';
import { useAppSelector } from '../../hooks';
import { Grid, Typography} from '@mui/material';

export const TrackChartsSection = () => {
  // Assuming userStats is an IUserStats object



  const userStats = useAppSelector((state) => state.track.currentTrack.stats);

  return <>
    <Grid container spacing={3}>
      {
        userStats?.subjectStats.map((subject) => {
          const data = [...subject.topics].sort((a, b) => {
            return b.correct / b.total - a.correct / a.total;
          }).slice(0,10
          ).map(topic => ({
            name: topic.topic,
            correct: topic.correct,
            total: topic.total
          }));

          return (
            <Grid item xs={12} style={{
              overflowX: 'auto',
              overflowY: 'hidden'
            }}>
              <div key={subject.subjectId} style={{
                width: '100%',
              }}>
                <div>
                  <Typography> TOP 10 Topics - {subject.subject}</Typography>
                </div>

                <BarChart width={
                  3000
                } height={300} data={data}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="correct" fill="#8884d8" />
                  <Bar dataKey="total" fill="#82ca9d" />
                </BarChart>

              </div>
            </Grid>
          );
        })
      }
    </Grid>
  </>
}