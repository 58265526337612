import { AppBar, Box, Button, Dialog, Slide, FormControl, FormControlLabel, Grid, IconButton, Paper, Radio, RadioGroup, Toolbar, Typography, useMediaQuery, useTheme, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { useEffect, useState } from "react"
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import { Bounce, toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import React from "react";
import { TransitionProps } from "@mui/material/transitions";
import { useNavigate, useParams } from "react-router-dom";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useQuestionSetService } from "../../services/question-set/useQuestionSetService";
import { useAppSelector } from "../../hooks";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const QuestionsPage = () => {
  const { id } = useParams()

  const navigate = useNavigate()

  const theme = useTheme()

  const questions = useAppSelector((state) => state.questionSet.questions)
  const answered = useAppSelector((state) => state.questionSet.answered)
  const activeQuestionIndex = useAppSelector((state) => state.questionSet.activeQuestionIndex)
  const currentQuestionSet = useAppSelector((state) => state.questionSet.currentQuestionSet)
  const finished = useAppSelector((state) => state.questionSet.finished)
  const { setCurrentQuestionSet, addAnswer, resetAnswered, nextQuestion, resetQuestionSet } = useQuestionSetService()

  const [openWarningDialog, setOpenWarningDialog] = useState(false)
  const [answerValue, setAnswerValue] = useState('');

  const handleClose = () => {
    if (!currentQuestionSet?.ended) {
      setOpenWarningDialog(true)
    } else {
      navigate(-1)
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnswerValue((event.target as HTMLInputElement).value);
  };


  useEffect(() => {
    resetQuestionSet()
    if (id !== '' && id !== undefined) {
      setCurrentQuestionSet({
        id: id
      })
    }
  }, [id, resetQuestionSet, setCurrentQuestionSet])

  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  return <>
    <Dialog
      open={openWarningDialog}
      onClose={() => {
        setOpenWarningDialog(false)
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Are you sure you want to close?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You will lose your progress, and all unanswered questions will be marked as incorrect.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setOpenWarningDialog(false)
        }}>Disagree</Button>
        <Button onClick={() =>
          navigate(-1)
        } autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog
      fullScreen
      open={true}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Question Set

          </Typography>
        </Toolbar>
      </AppBar>
      {
        questions.length > 0 ? (
          <Paper style={{
            marginTop: '30px'
          }}>
            <Box p={3}>
              {
                !finished ? (
                  <Grid container spacing={3}>
                    <Grid item>
                      <Typography fontWeight={"bold"} color="primary">
                        Questions
                      </Typography>

                    </Grid>
                    <Grid item>
                      <Typography fontWeight={"bold"}>
                        {activeQuestionIndex + 1} / 10
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">
                        {questions[activeQuestionIndex].statement}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl>
                        <RadioGroup

                          value={
                            currentQuestionSet?.ended ? (
                              currentQuestionSet?.answers[activeQuestionIndex]
                            ) : answerValue
                          }
                          onChange={(e) => {
                            if (!answered) {
                              handleChange(e)
                            }
                          }}
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          {
                            questions[activeQuestionIndex].answers.map((item, index) => {
                              let color = ''
                              if (answered || currentQuestionSet?.ended) {
                                if (questions[activeQuestionIndex].correctAnswer === item) {
                                  color = '#26ff5c'
                                } else {
                                  color = '#ff1919'
                                }
                              }
                              return <FormControlLabel value={item} control={<Radio
                                sx={{
                                  margin: isSm ? '25px 0' : '',
                                  color: color,
                                  '&.Mui-checked': {
                                    color: color,
                                  },

                                }}
                              />} label={item} key={index} />
                            })
                          }
                        </RadioGroup>
                      </FormControl>

                    </Grid>
                    {
                      answered ? (
                        <Grid item>
                          <Paper elevation={3}>
                            <Box p={3}>
                              <Box display={"flex"} alignItems={"center"}>
                                <Typography variant="h6" color="primary" fontWeight={"bold"}>
                                  Explanation
                                </Typography>
                                <EmojiObjectsIcon style={{
                                  marginLeft: '5px'
                                }} color="primary" />
                              </Box>
                              <Typography variant="h6">
                                {questions[activeQuestionIndex].explanation}
                              </Typography>
                            </Box>
                          </Paper>
                        </Grid>
                      ) : null
                    }
                    <Grid item xs={12}>
                      <Button variant="contained" onClick={() => {
                        let type = ''
                        if (answerValue === questions[activeQuestionIndex].correctAnswer) {
                          type = 'success'
                        } else {
                          type = 'error'
                        }
                        if (!(answered || currentQuestionSet?.ended)) {
                          if (answerValue !== '') {
                            addAnswer({
                              answer: answerValue,
                              type: type as 'success' | 'error'
                            })
                          } else {
                            toast('Select an answer.', {
                              position: "top-center",
                              type: 'warning',
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "dark",
                              transition: Bounce,
                            });
                          }
                        } else {
                          nextQuestion()
                          resetAnswered()
                          setAnswerValue("")
                        }
                      }}>

                        {
                          answered || currentQuestionSet?.ended ? (
                            'Next Question'
                          ) : 'Send answer'
                        }

                      </Button>
                    </Grid>
                  </Grid>
                ) : <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography align="center" variant="h4" color="primary" fontWeight={"bold"}>
                      Question Set Finished!
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container direction={"column"} alignItems={"center"} spacing={3}>
                      <Grid item>
                        <CheckCircleOutlineIcon fontSize="large" color="primary" />
                      </Grid>
                      <Grid item>
                        <Button onClick={() => {
                          navigate(-1)
                        }}>
                          Click to return
                        </Button>
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>
              }

            </Box >
          </Paper >
        ) : null
      }

    </Dialog>


  </>
}