import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { TrackPage } from "./pages/workspace/TrackPage"
import { WorkspaceLayout } from "./WorkspaceLayout"
import { QuestionsPage } from "./pages/workspace/QuestionsPage"
import { TracksPage } from "./pages/workspace/TracksPage"
import { SignUpPage, SignInPage, PasswordResetPage, RequestNewPasswordPage } from "./pages/auth"
import { EmailConfirmationPage } from "./pages/auth/email-confirmation"
import { SubjectPage } from "./pages/workspace/SubjectPage"
import { AccountPage } from "./pages/workspace/AccountPage"

export const MainRouter = () => {
  return <BrowserRouter>
    <Routes>
      <Route path="workspace" element={<WorkspaceLayout />}>
        <Route path="question-set/:id" element={<QuestionsPage />} />
        <Route path="user">
          <Route path="" element={<AccountPage />}>
          </Route>
        </Route>
        <Route path="track">
          <Route path="" element={<TracksPage />} />
          <Route path=":id">
            <Route path="" element={<TrackPage />} />
            <Route path="subject">
              <Route path=":subjectId" element={<SubjectPage />} />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path="auth">
        <Route path="sign-up" element={<SignUpPage />} />
        <Route path="sign-in" element={<SignInPage />} />
        <Route path="reset" element={<PasswordResetPage />} />
        <Route path="request-new-password" element={<RequestNewPasswordPage />} />
        <Route path="confirm" element={<EmailConfirmationPage />} />
      </Route>
      <Route path="*" element={<Navigate to={"/workspace/track"} />} />
    </Routes>
  </BrowserRouter>
}