
import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from './auth';
import { uiReducer } from './ui';
import { userReducer } from './user';
import { trackReducer } from './track';
import { questionSetReducer } from './question-set';
import { subjectReducer } from './subject';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    ui: uiReducer,
    track: trackReducer,
    questionSet: questionSetReducer,
    subject: subjectReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;