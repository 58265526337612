import { Avatar, Button, CircularProgress, Container, Grid, IconButton, TextField, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useAppSelector } from "../../hooks"
import { useUserService } from "../../services/user"
import { useEffect, useState } from "react"

export const AccountPage = () => {
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.down('lg'))
  const currentUser = useAppSelector((state) => state.user.currentUser)
  const updateUserForm = useAppSelector((state) => state.user.updateUserForm)
  const { setUpdateUserFormName, updateUser } = useUserService()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setUpdateUserFormName({
      name: currentUser.name
    })
  }, [currentUser.name, setUpdateUserFormName])

  return <Grid container style={{
    overflowY: isSm ? 'auto' : undefined,
  }} spacing={3}>
    <Grid item xs={12} display={"flex"} justifyContent={"center"}>
      <Tooltip title="Account settings">
        <IconButton
          onClick={() => {
          }}
          size="small"
        >
          <Avatar sx={{ width: 64, height: 64 }}>{currentUser.name[0]?.toUpperCase()}</Avatar>
        </IconButton>
      </Tooltip>
    </Grid>
    <Grid item xs={12} display={"flex"} justifyContent={"center"}>
      <Typography variant="h4" fontWeight={"bold"} textTransform={'none'} >
        {
          currentUser?.name
        }
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <form onSubmit={async (e) => {
        setLoading(true)
        e.preventDefault()
        await updateUser()
        setLoading(false)
      }}>
        <Container maxWidth="md">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                disabled={loading}
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                value={updateUserForm.fields.name}
                onChange={(e) => {
                  setUpdateUserFormName({
                    name: e.target.value
                  })
                }}
                error={Boolean(updateUserForm.touched && updateUserForm.errors.find(item => item.key === 'name'))}
                helperText={
                  updateUserForm.errors.find(item => item.key === 'name')?.message
                }
                autoFocus
              />
            </Grid>
            <Grid item xs={12} display={"flex"} justifyContent={"center"}>
              {
                loading ? (
                  <CircularProgress />
                ) : <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Update
                </Button>
              }

            </Grid>
          </Grid>
        </Container>
      </form>
    </Grid>
  </Grid>
}