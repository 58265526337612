import { useCallback } from "react"
import { useAxiosInstance } from "../axios-instance"
import { IUser } from "../../states/user"
interface IUserApiModel {
  user: {
    "id": number,
    "name": string
    "email": string
  }
}
export const useUserApi = () => {
  const { axiosPublicInstance, axiosPrivateInstance } = useAxiosInstance()

  const createUserApi = useCallback((data: {
    name: string,
    email: string,
    password: string
  }): Promise<IUser> => {
    return axiosPublicInstance.post("/user", data).then((res) => {
      return res.data
    }).catch((err) => {
      throw new Error(err?.response?.data?.message ?? 'Server Unknown Error')
    })
  }, [axiosPublicInstance])

  const findUserByIdApi = useCallback(async (data: {
    id: string
  }) => {
    try {

      const response = await axiosPrivateInstance.get<IUserApiModel>(`/user/${data.id}`)
      return {
        ...response.data.user
      }
    } catch (err: any) {
      throw new Error(err.response.data.message)
    }
  }, [
    axiosPrivateInstance,
  ])

  const updateUserApi = useCallback((data: {
    id: string,
    name: string
  }): Promise<IUser> => {
    return axiosPrivateInstance.put(`/user/${data.id}`, data).then((res) => {
      return res.data.user
    }).catch((err) => {
      throw new Error(err?.response?.data?.message ?? 'Server Unknown Error')
    })
  }, [axiosPrivateInstance])
  

  return {
    createUserApi,
    findUserByIdApi,
    updateUserApi
  }
}