import axios from "axios"
import { useMemo } from "react"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { resetAuthState } from "../../states/auth"

export const useAxiosInstance = () => {
  const token = useAppSelector((state) => state.auth.token)
  const tokenReady = useAppSelector((state) => state.auth.tokenReady)
  const dispatch = useAppDispatch()

  const axiosPublicInstance = useMemo(() => {
    return axios.create({
      baseURL: process.env.REACT_APP_API_URL
    })
  }, [])

  const axiosPrivateInstance = useMemo(() => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          
          if(tokenReady && token !== ''){
            window.localStorage.removeItem('token')
            dispatch(resetAuthState())
            window.location.href = '/auth/sign-in'
          }
        }
        return Promise.reject(error);
      }
    );

    return instance

  }, [
    token,
    dispatch,
    tokenReady
  ])

  return {
    axiosPublicInstance,
    axiosPrivateInstance
  }
}