import { useCallback } from 'react'
import { ICommon } from '../models/ICommon'
import { useAxiosInstance } from './axios-instance'

export interface IUserStats {
  subjectStats: {
    trackId: string
    subjectId: string
    subject: string
    topics: {
      topic: string
      topicId: string
      total: number
      correct: number
    }[]
    total: number
    correct: number
  }[]
}

export interface IQuestionSet extends ICommon {
  questions: string[]
  answers: string[]
  userId: string
  ended: boolean
  trackId: string
  topicId: string
  correct: number
  total: number
  subjectName: string
  subjectId: string
  topicName: string
  trackName: string
}


export interface IQuestion extends ICommon {
  statement: string
  answers: string[]
  correctAnswer: string
  topic: string
  explanation: string
}

export const useQuestionSetApi = () => {
  const { axiosPrivateInstance } = useAxiosInstance()
  const getUserStatsApi = useCallback(async (data: {
    userId: string,
    trackId?: string,
    subjectId?: string
  }): Promise<{
    subjectStats: IUserStats['subjectStats']
    lastTopics: string[]
  }> => {
    const response = await axiosPrivateInstance.get(`question-set/get-user-stats`, {
      params: {
        userId: data.userId,
        trackId: data.trackId,
        subjectId: data.subjectId
      }
    })
    return {
      subjectStats: response.data.list,
      lastTopics: response.data.lastTopics
    }
  }, [axiosPrivateInstance])


  const getLastTopicsInfoApi = useCallback(async (data: {
    userId: string,
    trackId?: string
  }): Promise<{
    topic: string
    topicId: string
    total: number
    correct: number
  }[]> => {
    const response = await axiosPrivateInstance.get(`question-set/get-last-topics-info`, {
      params: {
        userId: data.userId,
        trackId: data.trackId
      }
    })
    return response.data.list
  }, [axiosPrivateInstance])

  const createQuestionSetApi = useCallback(async (data: {
    topic?: string
    trackId: string
    userId: string,
    prompt: string,
    auto?: boolean
  }): Promise<IQuestionSet> => {
    const response = await axiosPrivateInstance.post(`/question-set`, {
      data: {
        ...data
      }
    })

    return response.data.questionSet

  }, [axiosPrivateInstance])


  const findQuestionSetByIdApi = useCallback(async (data: {
    id: string
  }): Promise<IQuestionSet> => {
    return axiosPrivateInstance.get(`/question-set/${data.id}`).then((response) => {
      return response.data.questionSet
    })
  }, [axiosPrivateInstance])


  const findQuestionsByIdsApi = useCallback(async (data: {
    ids: string[]
  }): Promise<IQuestion[]> => {
    return axiosPrivateInstance.get(`/question`, {
      params: {
        limit: 10,
        offset: 0,
        filter: {
          ids: data.ids
        }
      }
    }).then((response) => {
      return response.data.questions
    })
  }, [axiosPrivateInstance])

  const updateQuestionSetApi = useCallback(async (data: {
    questionSetId: string,
    answers?: string[],
    ended?: boolean
  }): Promise<IQuestionSet> => {
    return axiosPrivateInstance.put(`/question-set/${data.questionSetId}`, {
      data: {
        answers: data.answers,
        ended: data.ended
      }
    }).then((response) => {
      return response.data.questionSet
    })

  }, [axiosPrivateInstance])


  const getQuestionSetListApi = useCallback(async (data: {
    subjectId: string
  }): Promise<IQuestionSet[]> => {
    const response = await axiosPrivateInstance.get(`/question-set`, {
      params: {
        filter: {
          subjectId: data.subjectId
        }
      }
    })
    return response.data.questionSets
  }, [axiosPrivateInstance])


  return {
    getUserStatsApi,
    createQuestionSetApi,
    findQuestionSetByIdApi,
    findQuestionsByIdsApi,
    updateQuestionSetApi,
    getLastTopicsInfoApi,
    getQuestionSetListApi
  }
}