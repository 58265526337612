import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from "react-router-dom";
export const menuItensTop: {
  value: string,
  icon: JSX.Element
  path: string,
}[] = [
  {
    icon: <HomeIcon color='secondary' />,
    value: 'Home',
    path: '/workspace/tracks'
  },
    {
      icon: <AccountCircleIcon color='secondary' />,
      value: 'Account',
      path: '/workspace/user'
    }
  ]

export const ListMenu = () => {
  const navigate = useNavigate()
  return <Box
    role="presentation"
  >
    <List>
      {menuItensTop.map((item, index) => (
        <ListItem key={item.value} disablePadding onClick={() => {
          navigate(item.path)
        }}>
          <ListItemButton>
            <ListItemIcon>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.value} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  </Box>
}
 

