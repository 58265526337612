import { Box, Grid, IconButton, Paper, Typography, useMediaQuery, useTheme } from "@mui/material"
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useNavigate } from "react-router-dom";
import { CircularProgressWithLabel } from "./CircularProgressWithLabel";
export const SubjectStats = (data: {
  subject: string,
  subjectId: string,
  subjectTotal: number,
  subjectCorrect: number
  trackId?: string
  topicName?: string
}) => {

  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.down('md'))
  const navigate = useNavigate()
  return <Paper elevation={3} sx={{
    borderRadius: theme.shape.borderRadius
  }}>
    <Box p={3}>
      <Grid container spacing={3} alignItems={"center"} justifyContent={'space-between'} flexWrap={"nowrap"}>
        <Grid item xs={isSm ? 12 : undefined}>
          <Typography color={
            data.topicName ? 'primary' : 'secondary'
          } fontWeight={"bold"} variant="subtitle1">
            {
              data.topicName ? data.topicName : data.subject
            }
          </Typography>
          {
            data.topicName && <Typography  color="textSecondary" variant="caption">
              Subject: <Typography variant="caption" color="secondary" fontWeight={"bold"} display={"inline"}>{data.subject} </Typography>
            </Typography>
          }
        </Grid>
        <Grid item>
          <Grid container alignItems={"center"} flexWrap={"nowrap"} spacing={1}>
            <Grid item>
              <CircularProgressWithLabel color={
                data.subjectTotal === 0 ? undefined : ((data.subjectCorrect / data.subjectTotal) < 0.5 ? 'error' : 'success')
              } value={
                data.subjectTotal === 0 ? 0 : ((data.subjectCorrect  /  data.subjectTotal) * 100)
              } />
            </Grid>
            <Grid item>
              <IconButton color="primary" aria-label="delete" onClick={() => {
                if (data.trackId) {
                  navigate(`/workspace/track/${data.trackId}/subject/${data.subjectId}`)
                }
              }}>
                <OpenInNewIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

      </Grid>

    </Box>
  </Paper>
}