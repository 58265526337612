import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useAppSelector } from '../../../hooks';
import { useUserService } from '../../../services/user/useUserService';
import { CircularProgress } from '@mui/material';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

export function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://app.Quiron Std.com">
        StratZord
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}



export function SignUpPage() {
  const [loading, setLoading] = React.useState(false)
  const signUpForm = useAppSelector((state) => state.user.signUpForm)
  const {
    setSignUpFormEmail,
    setSignUpFormConfirmEmail,
    setSignUpFormName,
    setSignUpFormPassword,
    setSignUpFormConfirmPassword,
    createUser
  } = useUserService()

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true)
    createUser().then(() => {
      setLoading(false)
    })
  };
  return (

    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {
          signUpForm.delivered ? (
            <>
              <Typography style={{
                marginTop: '30px'
              }} variant="h4" align="center" color="secondary">
                Account created Successfully
              </Typography>
              <Typography variant="subtitle1" color="secondary" align="center">
                We have sent a confirmation email to your inbox. Please check and follow the instructions to complete your account setup.
              </Typography>
              <Box display={"flex"} justifyContent={"center"}>
                <MarkEmailReadIcon color="secondary" fontSize='large' />
              </Box>
              <Box display={"flex"} justifyContent={"center"} mt={2}>
                <Button onClick={() => {
                  window.location.href = '/auth/sign-in'
                }} color="secondary" variant="outlined">
                  Sign in
                </Button>
              </Box>
              <Box display={"flex"} justifyContent={"center"} mt={2}>
                <img src="/logo.png" alt="Logo Quiron Std" style={{
                  width: '200px',
                  maxWidth: '100%'
                }} />
              </Box>
            </>
          ) : <>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" color="secondary">
              Sign up
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} >
                  <TextField
                    value={signUpForm.fields.name}
                    onChange={(e) => {
                      setSignUpFormName({
                        name: e.target.value
                      })
                    }}
                    error={Boolean(signUpForm.touched && signUpForm.errors.find(item => item.key === 'name'))}
                    helperText={
                      signUpForm.errors.find(item => item.key === 'name')?.message
                    }
                    autoComplete="given-name"
                    name="name"
                    fullWidth
                    id="name"
                    label="Name"
                    autoFocus
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="email"
                    value={signUpForm.fields.email}
                    onChange={(e) => {
                      setSignUpFormEmail({
                        email: e.target.value
                      })
                    }}
                    error={Boolean(signUpForm.touched && signUpForm.errors.find(item => item.key === 'email'))}
                    helperText={
                      signUpForm.errors.find(item => item.key === 'email')?.message
                    }
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="confirm-email"
                    value={signUpForm.fields.confirmEmail}
                    onChange={(e) => {
                      setSignUpFormConfirmEmail({
                        confirmEmail: e.target.value
                      })
                    }}
                    error={Boolean(signUpForm.touched && signUpForm.errors.find(item => item.key === 'confirmEmail'))}
                    helperText={
                      signUpForm.errors.find(item => item.key === 'confirmEmail')?.message
                    }
                    label="Confirm Email Address"
                    name="confirm-email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={signUpForm.fields.password}
                    onChange={(e) => {
                      setSignUpFormPassword({
                        password: e.target.value
                      })
                    }}
                    error={Boolean(signUpForm.touched && signUpForm.errors.find(item => item.key === 'password'))}
                    helperText={
                      <div dangerouslySetInnerHTML={{
                        __html: signUpForm.errors.find(item => item.key === 'password')?.message ?? ''
                      }}>
                      </div>
                    }
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={signUpForm.fields.confirmPassword}
                    onChange={(e) => {
                      setSignUpFormConfirmPassword({
                        confirmPassword: e.target.value
                      })
                    }}
                    error={Boolean(signUpForm.touched && signUpForm.errors.find(item => item.key === 'confirmPassword'))}
                    helperText={
                      signUpForm.errors.find(item => item.key === 'confirmPassword')?.message
                    }
                    fullWidth
                    name="confirmPassword"
                    label="ConfirmPassword"
                    type="password"
                    id="confirmPassword"
                  />
                </Grid>
                {/* <Grid item xs={12}>
              <FormControlLabel

                control={<Checkbox name='allowExtraEmails'
                  id='allowExtraEmails' color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid> */}
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading}
              >
                {
                  loading ? (
                    <CircularProgress />
                  ) : "Sign Up"
                }
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link href="/auth/sign-in" variant="body2">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </>
        }
      </Box >
      <Copyright sx={{ mt: 5 }} />
    </Container >
  );
}