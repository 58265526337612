import { useCallback } from "react"
import { useUserApi } from "../../api/user"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { setCurrentUserState, setSignUpFormConfirmEmailState, setSignUpFormConfirmPasswordState, setSignUpFormDeliveredState, setSignUpFormEmailState, setSignUpFormErrorsState, setSignUpFormNameState, setSignUpFormPasswordState, setSignUpFormTouchedState, setUpdateUserFormErrorsState, setUpdateUserFormNameState, setUpdateUserFormTouchedState, validateSignUpForm, validateUpdateUserForm } from "../../states/user"
import { useUiService } from "../ui"
import { sha512 } from "js-sha512"
export const useUserService = () => {

  const dispatch = useAppDispatch()

  const signUpForm = useAppSelector((state) => state.user.signUpForm)
  const updateUserForm = useAppSelector((state) => state.user.updateUserForm)
  const currentUser = useAppSelector((state) => state.user.currentUser)
  const { setAlert } = useUiService()
  const { createUserApi, updateUserApi } = useUserApi()
  const setSignUpFormName = useCallback((data: {
    name: string
  }) => {
    dispatch(setSignUpFormNameState({
      name: data.name
    }))
  }, [dispatch])

  const setSignUpFormEmail = useCallback((data: {
    email: string
  }) => {
    dispatch(setSignUpFormEmailState({
      email: data.email
    }))
  }, [dispatch])

  const setSignUpFormConfirmEmail = useCallback((data: {
    confirmEmail: string
  }) => {
    dispatch(setSignUpFormConfirmEmailState({
      confirmEmail: data.confirmEmail
    }))
  }, [dispatch])

  const setSignUpFormPassword = useCallback((data: {
    password: string
  }) => {
    dispatch(setSignUpFormPasswordState({
      password: data.password
    }))
  }, [dispatch])

  const setSignUpFormConfirmPassword = useCallback((data: {
    confirmPassword: string
  }) => {
    dispatch(setSignUpFormConfirmPasswordState({
      confirmPassword: data.confirmPassword
    }))
  }, [dispatch])


  const createUser = useCallback(async () => {
    const errors = validateSignUpForm(signUpForm.fields)
    if (errors.length > 0) {
      dispatch(setSignUpFormTouchedState({
        touched: true
      }))
      dispatch(setSignUpFormErrorsState({
        errors: errors
      }))
      return
    } else {
      return createUserApi({
        email: signUpForm.fields.email,
        name: signUpForm.fields.name,
        password: sha512(signUpForm.fields.password)
      }).then((response) => {

        dispatch(setSignUpFormDeliveredState({
          delivered: true
        }))
        setAlert({
          message: 'User created successfully',
          type: 'success'
        })
      }).catch((err) => {
        setAlert({
          message: err?.message ?? 'Unknown Error',
          type: 'error'
        })
      })
    }
  }, [
    createUserApi,
    dispatch,
    setAlert,
    signUpForm.fields
  ])

  const setUpdateUserFormName = useCallback((data: {
    name: string
  }) => {
    dispatch(setUpdateUserFormNameState({
      name: data.name
    }))
  }, [dispatch])

  const updateUser = useCallback(async () => {
    const erros = validateUpdateUserForm(updateUserForm.fields)
    if (erros.length > 0) {
      dispatch(setUpdateUserFormTouchedState({
        touched: true
      }))
      dispatch(setUpdateUserFormErrorsState({
        errors: erros
      }))
      return
    } else {
      return updateUserApi({
        id: currentUser.id,
        name: updateUserForm.fields.name
      }).then((response) => {
        setAlert({
          message: 'User updated successfully',
          type: 'success'
        })
        dispatch(setCurrentUserState({
          user: response
        }))
        return
      }).catch((err) => {
        setAlert({
          message: err?.message ?? 'Unknown Error',
          type: 'error'
        })
        return
      })
    }
  }, [currentUser.id, dispatch, setAlert, updateUserApi, updateUserForm.fields])

  return {
    setSignUpFormName,
    setSignUpFormEmail,
    setSignUpFormPassword,
    setSignUpFormConfirmPassword,
    createUser,
    setSignUpFormConfirmEmail,
    setUpdateUserFormName,
    updateUser
  }
}