import { Box, Grid, Typography } from "@mui/material"
import { useAppSelector } from "../hooks"
import InfoIcon from '@mui/icons-material/Info';
import { QuestionSetCard } from "./QuestionSetCard";

export const LastQuestionSetsSection = () => {
  const lastQuestionSets = useAppSelector((state) => state.subject.lastQuestionSets)
  return <>
    <Box p={3}>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            Last question sets
          </Typography>
        </Grid>
        {
          lastQuestionSets.length === 0 ? (<>
            <Grid item xs={12}>
              <Typography variant="body1" align="center">
                No question sets to show, start creating your learning pathways and questions
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent={"center"}>
                <InfoIcon fontSize="large" />
              </Box>
            </Grid>
          </>
          ) : <Grid item xs={12}>
            <Grid container spacing={1}>
              {
                lastQuestionSets.map((questionSet) => {

                  return <Grid item xs={12} key={questionSet.id}>
                    <QuestionSetCard
                      questionSetId={questionSet.id}
                      correct={questionSet.correct}
                      total={questionSet.total}
                      subject={questionSet.subjectName}
                      topicName={questionSet.topicName}
                      trackName={questionSet.trackName}
                    />
                  </Grid>

                })
              }
            </Grid>
          </Grid>
        }
      </Grid>
    </Box>
  </>
}