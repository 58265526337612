import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type IAlertType = 'error' | 'warning' | 'success'

interface IUISliceState {
  alertChangesOpen: boolean
  alertChangesAction: () => void

}

const initialState: IUISliceState = {
  alertChangesOpen: false,
  alertChangesAction: () => {

  }
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setUIAlertState(state, action: PayloadAction<{
      type: IAlertType,
      message: string
    }>) {

    },
    setAlertChangesOpenState(state, action: PayloadAction<{
      open: boolean
    }>) {
      state.alertChangesOpen = action.payload.open
    },
    setAlertChangesActionState(state, action: PayloadAction<{
      action: () => void
    }>) {
      state.alertChangesAction = action.payload.action
    },

  },
});

export const {
  setUIAlertState,
  setAlertChangesOpenState,
  setAlertChangesActionState
} = uiSlice.actions;
export const uiReducer = uiSlice.reducer;