import { Outlet } from "react-router-dom"
import { Header } from "./Header"
import { Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, Paper, TextField, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useAuthService } from "./services/auth"
import { useEffect, useMemo } from "react"
import { useAppSelector } from "./hooks"
import { NavMenu } from "./components/menus/NavMenu"
import { useQuestionSetService } from "./services/question-set/useQuestionSetService"
import { DialogsSection } from "./pages/workspace/DialogsSection"

export const WorkspaceLayout = () => {
  const { setupSession } = useAuthService()
  const tokenReady = useAppSelector((state) => state.auth.tokenReady)
  useEffect(() => {
    setupSession()

  }, [setupSession])

  const theme = useTheme()

  const isSm = useMediaQuery(theme.breakpoints.down('sm'))

  const viewHeight = useMemo(() => {
    return window.innerHeight - (isSm ? 0 : 120)
  }, [isSm])


  return <>

    <DialogsSection />
    <Box sx={{
      maxHeight: '100vh',
    }}>
      {
        !tokenReady ? <div>Loading...</div> : <>
          <Header />
          <Box sx={{
            marginTop: '80px',
          }}>
            <Box style={{
              height: isSm ? undefined : viewHeight
            }}>
              <Grid container spacing={3} style={{
                height: '100%',
              }}>
                <Grid item lg={2}>
                  <NavMenu />
                </Grid>
                <Grid item lg={10} xs={12}>
                  <Paper elevation={1}>
                    <Box p={
                      isSm ? 1 : 3
                    }
                      sx={{
                        height: isSm ? 'calc(100vh - 120px)' : 'calc(100vh - 120px)',
                        width: '100%',
                        overflowY: 'hidden',
                      }}
                    >
                      <Outlet />
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      }
    </Box>
  </>
}