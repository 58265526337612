import { Dialog, DialogTitle, DialogContent, DialogContentText, Typography, Tooltip ,TextField, Box, FormControlLabel, Checkbox, DialogActions, Button, CircularProgress } from "@mui/material"
import { useAppSelector } from "../../hooks"
import { useQuestionSetService } from "../../services/question-set/useQuestionSetService"
import { useState } from "react"

export const DialogsSection = () => {
  const createQuestionSetDialogOpen = useAppSelector((state) => state.questionSet.ui.createQuestionSetDialogOpen)
  const { setCreateQuestionSetDialogOpen, createQuestionSet } = useQuestionSetService()
  const topicId = useAppSelector((state) => state.questionSet.ui.selectedTopicId)
  const topicName = useAppSelector((state) => state.questionSet.ui.selectedTopicName)
  const [loading, setLoading] = useState(false)
  return <>
    {
      createQuestionSetDialogOpen && (
        <Dialog
          fullWidth
          maxWidth={"md"}
          open={true}
          onClose={() => {
            setCreateQuestionSetDialogOpen({
              open: false
            })
          }}
          PaperProps={{
            component: 'form',
            onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              setLoading(true)
              await createQuestionSet({
                topic: topicId,
                prompt: '',
                auto: true
              })
              setCreateQuestionSetDialogOpen({
                open: false
              })
              setLoading(false)
            },
          }}
        >
          <DialogTitle>Question Set</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Input your learning interest or objective. This prompt will guide the generation of your questions
              <Typography style={{
                marginTop: '10px'
              }}>
                Topic: <Typography display={"inline"} color="primary" fontWeight={"bold"}> {
                  topicName
                } </Typography>
              </Typography>
            </DialogContentText>
            <TextField
              style={{
                marginTop: '20px'
              }}
              id="outlined-multiline-static"
              label="Type your prompt here"
              multiline
              rows={4}
              defaultValue=""
              fullWidth
            // value={promptValue}
            // onChange={(e) => {
            //   setPromptValue(e.target.value)
            // }}
            />
            <Box display="flex" alignItems="center">
              <FormControlLabel control={<Checkbox checked={true} onChange={(e) => {
                // setChecked(e.target.checked)
              }} />} label="Select questions based on your stats" />
              <Tooltip title="The questions will be selected based on your stats to enhance your learning by balancing between questions you perform well on and those you struggle with.">
                {/* <InfoIcon /> */}
                <>
                  a
                </>
              </Tooltip>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setCreateQuestionSetDialogOpen({
                open: false
              })
            }}>Cancel</Button>
            {
              loading ? (
                <Box p={3}>
                  <CircularProgress />
                </Box>
              ) : <Button type="submit">create</Button>
            }

          </DialogActions>
        </Dialog>
      )
    }
  </>
}