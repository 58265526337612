import { Box, Button, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Paper, Typography } from "@mui/material"
import { ITrack } from "../models/ITrack"
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useNavigate } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import React from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTrackService } from "../services/track/useTrackService";
export const TrackCard = (props: {
  track: ITrack,
  getTracks: any
}) => {
  const { deleteTrack } = useTrackService()

  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return <>
    <Paper elevation={5} sx={{
      height: 180,
      display: 'flex',
      position: 'relative'
    }}>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuList>
          <MenuItem
            onClick={() => {
              deleteTrack({
                trackId: props.track.id
              })
            }}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Delete track</ListItemText>
          </MenuItem>
        </MenuList>

      </Menu>
      <IconButton style={{
        position: 'absolute',
        top: 5,
        right: 0
      }} aria-label="delete" onClick={(e) => {
        handleClick(e)
      }}>
        <MoreVertIcon />
      </IconButton>
      <Box p={3}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography color="primary" variant="h6"
              style={
                {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }
              }
            >
              {props.track.name}
            </Typography>

          </Grid>
          <Grid item xs={12}>
            <Box height={40}>
              <Typography
                color={'secondary'}
                variant="caption"
                style={
                  {
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }
                }
              >
                {props.track.prompt}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={() => {
              navigate(`/workspace/track/${props.track.id}`)
            }} style={{
              margin: '10px 0'
            }} variant="contained" endIcon={<OpenInNewIcon />}>
              Open Track
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper >
  </>
}



