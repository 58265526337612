import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IQuestionSet } from '../../api'

export type IAlertType = 'error' | 'warning' | 'success'

export interface ITopic {
  id: string
  name: string
  correct: number
  total: number
}

export interface ISubject {
  id: string
  name: string
  topics: ITopic[]
  total ?: number
  correct ?: number
}

interface ISubjectState {
  currentSubject: ISubject
  currentTopic: ITopic
  lastQuestionSets: IQuestionSet[]
}

const initialState: ISubjectState = {
  currentSubject: {
    id: '',
    name: '',
    topics: []
  },
  currentTopic: {
    id: '',
    name: '',
    correct: 0,
    total: 0
  },
  lastQuestionSets: []
};

const subjectSlice = createSlice({
  name: 'subject',
  initialState,
  reducers: {
    setCurrentSubjectState(state, action: PayloadAction<{
      subject: ISubject
    }>) {
      state.currentSubject = action.payload.subject
    },
    setCurrentTopicState(state, action: PayloadAction<{
      topic: ITopic
    }>) {
      state.currentTopic = action.payload.topic
    },
    setLastQuestionSetsState(state, action: PayloadAction<{
      questionSets: IQuestionSet[]
    }>) {
      state.lastQuestionSets = action.payload.questionSets
    }
  },
});

export const {
  setCurrentSubjectState,
  setCurrentTopicState,
  setLastQuestionSetsState
} = subjectSlice.actions;
export const subjectReducer = subjectSlice.reducer;