import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IQuestion, IQuestionSet } from '../../api';


interface IQuestionSetState {
  currentQuestionSet: IQuestionSet | null,
  questions: IQuestion[]
  activeQuestionIndex: number
  answers: string[]
  answered: boolean
  finished: boolean
  lastTopicsInfo: {
    topic: string
    topicId: string
    total: number
    correct: number
  }[],
  ui: {
    createQuestionSetDialogOpen: boolean,
    selectedTopicId: string,
    selectedTopicName: string
  }
}

const initialState: IQuestionSetState = {
  currentQuestionSet: null,
  activeQuestionIndex: 0,
  answers: [],
  questions: [],
  answered: false,
  finished: false,
  lastTopicsInfo: [],
  ui: {
    createQuestionSetDialogOpen: false,
    selectedTopicId: '',
    selectedTopicName: ''
  }
};

const questionSetSlice = createSlice({
  name: 'questionSet',
  initialState,
  reducers: {
    setCurrentQuestionSetState(state, action: PayloadAction<{
      questionSet: IQuestionSet
    }>) {
      state.currentQuestionSet = action.payload.questionSet
    },
    setActiveQuestionIndexState(state, action: PayloadAction<{
      index: number
    }>) {
      state.activeQuestionIndex = action.payload.index
    },
    setAnswersState(state, action: PayloadAction<{
      answers: string[]
    }>) {
      state.answers = action.payload.answers
    },
    setQuestionsState(state, action: PayloadAction<{
      questions: IQuestion[]
    }>) {
      state.questions = action.payload.questions
    },
    setAnsweredState(state, action: PayloadAction<{
      answered: boolean
    }>) {
      state.answered = action.payload.answered
    },
    setFinishedState(state, action: PayloadAction<{
      finished: boolean
    }>) {
      state.finished = action.payload.finished
    },
    setLastTopicsInfoState(state, action: PayloadAction<{
      list: {
        topic: string
        topicId: string
        total: number
        correct: number
      }[]
    }>) {
      state.lastTopicsInfo = action.payload.list
    },
    setCreateQuestionSetDialogOpenState(state, action: PayloadAction<{
      open: boolean
    }>) {
      state.ui.createQuestionSetDialogOpen = action.payload.open
    },
    setSelectedTopicState(state, action: PayloadAction<{
      topicId: string,
      topicName: string
    }>) {
      state.ui.selectedTopicId = action.payload.topicId
      state.ui.selectedTopicName = action.payload.topicName
    },
    resetQuestionSetState(state) {
      state.activeQuestionIndex = 0
      state.answers = []
      state.questions = []
      state.answered = false
      state.finished = false
      state.lastTopicsInfo = []
      state.ui = {
        createQuestionSetDialogOpen: false,
        selectedTopicId: '',
        selectedTopicName: ''
      }
    }
  }
});

export const {
  setCurrentQuestionSetState,
  setActiveQuestionIndexState,
  setAnswersState,
  setQuestionsState,
  setAnsweredState,
  setFinishedState,
  setLastTopicsInfoState,
  setCreateQuestionSetDialogOpenState,
  setSelectedTopicState,
  resetQuestionSetState
} = questionSetSlice.actions;

export const questionSetReducer = questionSetSlice.reducer;