import { Box, Paper } from "@mui/material"
import { ListMenu } from "./list"

export const NavMenuMdLg = () => {
  return <>
    <Box style={{
      height: '100%'
    }}>
      <Paper style={{
        height: '100%'
      }}>
        <ListMenu />
      </Paper>
    </Box>
  </>
}