import { Box, Grid, Typography, IconButton, useTheme, useMediaQuery } from "@mui/material"
import { CircularProgressWithLabel } from "./CircularProgressWithLabel"
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { useNavigate } from "react-router-dom";
export const QuestionSetCard = (data: {
  questionSetId: string,
  subject: string,
  total: number,
  correct: number,
  trackName?: string,
  topicName?: string,

}) => {
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.down('md'))
  const navigate = useNavigate()
  return <>
    <Box p={3}>
      <Grid container spacing={3} alignItems={"center"} justifyContent={'space-between'} flexWrap={"nowrap"}>
        <Grid item xs={isSm ? 12 : undefined}>
          <Typography color={
            data.topicName ? 'primary' : 'secondary'
          } fontWeight={"bold"} variant="subtitle1">
            {
              data.topicName ? data.topicName : data.subject
            }
          </Typography>
          {
            data.topicName && <Typography color="textSecondary" variant="caption">
              Subject: <Typography variant="caption" color="secondary" fontWeight={"bold"} display={"inline"}>{data.subject} </Typography>
            </Typography>
          }
        </Grid>
        <Grid item>
          <Grid container alignItems={"center"} flexWrap={"nowrap"} spacing={1}>
            <Grid item>
              <CircularProgressWithLabel color={
                data.total === 0 ? undefined : ((data.correct / data.total) < 0.5 ? 'error' : 'success')
              } value={
                data.total === 0 ? 0 : ((data.correct / data.total) * 100)
              } />
            </Grid>
            <Grid item>
              <IconButton color="primary" aria-label="delete" onClick={() => {
                if (data.questionSetId) {
                  navigate(`/workspace/question-set/${data.questionSetId}`)
                }
              }}>
                <AssignmentTurnedInIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

      </Grid>

    </Box>
  </>
}