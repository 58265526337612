import { AppBar, Box, Button, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "./hooks";
import { AvatarMenuButton } from "./AvatarMenuButton";
import MobileDrawer from "./MobileDrawer";
import { Logo } from "./components/Logo";

export const Header = () => {
  const theme = useTheme()

  const navigate = useNavigate();

  const currentUser = useAppSelector((state) => state.user.currentUser)
  const isSm = useMediaQuery(theme.breakpoints.down('lg'))
  return <>
    <Box p={1} sx={{
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      zIndex: 500,
    }}>
      {
        currentUser.id ? (
          <>
            {
              isSm ? <>
                <AppBar>
                  <Grid container justifyContent={"space-between"} alignItems={'center'}>
                    <Grid item>
                      <MobileDrawer />
                    </Grid>
                    <Grid item>
                      <AvatarMenuButton />
                    </Grid>
                  </Grid>
                </AppBar>
              </> : <>
                <Logo />
                <AvatarMenuButton />
              </>
            }

          </>
        ) : <>
          <Box sx={{
            padding: theme.spacing(3),
          }}>
            <Grid container spacing={3} justifyContent={"flex-end"}>
              <Grid item>
                <Button variant="contained" color="primary" onClick={(e) => {
                  navigate("/auth/sign-in")
                }}>
                  Sign in
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={() => {
                  navigate("/auth/sign-up")
                }} variant="contained" color="secondary">
                  Sign up
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      }
    </Box>
  </>
}