import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ITrack } from '../../models/ITrack';
import { IUserStats } from '../../api';


export type ITrackPageActionsKey = 'charts' | 'list'
interface ITrackState {
  tracks: ITrack[],
  currentTrack: {
    track: ITrack | null,
    stats: IUserStats | null
    lastTopics: string[]
  }
  ui: {
    statsLoading: boolean
    trackPageAction: ITrackPageActionsKey
  }
}


const initialState: ITrackState = {
  tracks: [],
  currentTrack: {
    track: null,
    stats: null,
    lastTopics: []
  },
  ui: {
    statsLoading: false,
    trackPageAction: 'list'
  }
};

const trackSlice = createSlice({
  name: 'track',
  initialState,
  reducers: {
    setTracksState(state, action: PayloadAction<{
      tracks: ITrack[]
    }>) {
      state.tracks = action.payload.tracks
    },
    setCurrentTrackState(state, action: PayloadAction<{
      track: ITrack
    }>) {
      state.currentTrack.track = action.payload.track
    },
    setCurrentTrackStatsState(state, action: PayloadAction<{
      stats: IUserStats
    }>) {
      state.currentTrack.stats = action.payload.stats
    },
    setStatsLoadingState(state, action: PayloadAction<{
      loading: boolean
    }>) {
      state.ui.statsLoading = action.payload.loading
    },
    setTrackPageActionState(state, action: PayloadAction<{
      action: ITrackPageActionsKey
    }>) {
      state.ui.trackPageAction = action.payload.action
    },
    setLastTopicsState(state, action: PayloadAction<{
      lastTopics: string[]
    }>) {
      state.currentTrack.lastTopics = action.payload.lastTopics
    }
  },
});

export const {
  setTracksState,
  setCurrentTrackState,
  setCurrentTrackStatsState,
  setStatsLoadingState,
  setTrackPageActionState,
  setLastTopicsState
} = trackSlice.actions;
export const trackReducer = trackSlice.reducer;