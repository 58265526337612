import { Box, Grid, Typography } from "@mui/material"
import { SubjectStats } from "./SubjectStats"
import { useAppSelector } from "../hooks"
import InfoIcon from '@mui/icons-material/Info';

export const LastTopicsSection = () => {
  const lastTopics = useAppSelector((state) => state.track.currentTrack.lastTopics)
  const stats = useAppSelector((state) => state.track.currentTrack.stats)
  return <>
    <Box p={3}>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            Last topics
          </Typography>
        </Grid>
        {
          lastTopics.length === 0 ? (<>
            <Grid item xs={12}>
              <Typography variant="caption" align="center" display={"block"}>
                Stats based on your last questions
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" align="center">
                No topics to show, start creating your learning pathways and questions
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent={"center"}>
                <InfoIcon fontSize="large" />
              </Box>
            </Grid>
          </>
          ) : <Grid item xs={12}>
            <Grid container spacing={1}>
              {
                lastTopics.map((lastTopic) => {
                  let topicName: any = {}
                  const itemSubject = stats?.subjectStats.find((item) => {
                    topicName = item.topics.find(i => i.topicId === lastTopic)?.topic
                    return topicName
                  })
                  if (itemSubject) {
                    return <Grid item xs={12} key={lastTopic}>
                      <SubjectStats
                        subjectId={itemSubject.subjectId}
                        subjectCorrect={Number(itemSubject.topics.find(i => i.topicId === lastTopic)?.correct)}
                        subjectTotal={Number(itemSubject.topics.find(i => i.topicId === lastTopic)?.total)}
                        subject={itemSubject.subject}
                        topicName={topicName}
                        trackId={itemSubject.trackId}
                      />
                    </Grid>
                  } else {
                    return <>
                    </>
                  }
                })
              }
            </Grid>
          </Grid>
        }
      </Grid>
    </Box>
  </>
}